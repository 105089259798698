export enum Directions {
    None = 0,
    Upsteam = 1,
    Downsteam = 2
}

export enum DataPoints {
    None = 0,
    Wade = 1,
    Usgs = 2,
    Epa = 4
}